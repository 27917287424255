import 'serviceworker-cache-polyfill';
import logoSrc from '../../server/logo.png';

const CURRENT_CACHE = 'showtimer-' + SHOWTIMER_VERSION;

self.addEventListener('install', function(event) {
    event.waitUntil(
        caches.open(CURRENT_CACHE).then(function(cache) {
            return cache.addAll([
                '/',
                'main.js',
                'main.js.map',
                logoSrc,
                'favicon.ico'
            ]);
        })
    );
});

// fetch the resource from the network
const fromNetwork = (request, timeout) =>
    new Promise((fulfill, reject) => {
        const timeoutId = setTimeout(reject, timeout);
        fetch(request).then(response => {
            clearTimeout(timeoutId);
            fulfill(response);
            update(request);
        }, reject);
    });

// fetch the resource from the browser cache
const fromCache = request =>
    caches
        .open(CURRENT_CACHE)
        .then(cache =>
            cache
                .match(request)
                .then(matching => matching || cache.match('/offline/'))
        );

// cache the current page to make it available for offline
const update = request =>
    caches
        .open(CURRENT_CACHE)
        .then(cache =>
            fetch(request).then(response => cache.put(request, response))
        );

self.addEventListener('fetch', function(evt) {
    evt.respondWith(
        fromNetwork(evt.request, 500).catch(() => fromCache(evt.request))
    );
    evt.waitUntil(update(evt.request));
});